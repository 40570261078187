import unwrapValueFromInputChangeEvent from './unwrapValueFromInputChangeEvent.helper';

const handleInputChange = (formControlName, originalEvent, setChanges, changes) => {
  const formControlUpdatedValue = unwrapValueFromInputChangeEvent(originalEvent);

  let formUpdate = { ...changes };
  if (formControlName === 'isCulturlabsAdmin') {
    console.log('here');

    formUpdate[formControlName] = formControlUpdatedValue === 'Admin';
    console.log('here');
  } else {
    formUpdate[formControlName] = formControlUpdatedValue || undefined;
  }

  setChanges(formUpdate);
};

export default handleInputChange;
