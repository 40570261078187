import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import unwrapValueFromInputChangeEvent from '../../helpers/unwrapValueFromInputChangeEvent.helper';
import AuthService, { setDefaultOrganizationRoleIfMissed } from '../../services/Auth.service';
import useAppStore from '../../store';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';
import CulturlabsLink from '../atoms/CulturlabsLink/CulturlabsLink';

const LoginPage = () => {
  const setLoginForm = useAppStore(state => state.setLoginForm);
  const loginFormStore = useAppStore(state => state.loginForm);
  const navigate = useNavigate();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginFormStore.identifier);
    const isPasswordValid = loginFormStore.password?.length >= 6;
    setFormValid(isEmailValid && isPasswordValid);
  }, [loginFormStore]);

  const onSubmit = async event => {
    event.preventDefault();

    const response = await AuthService.authenticate(loginFormStore).catch(emptyArrowFunc);
    if (response?.status === 200) {
      const { user } = response?.data;

      AuthService.handleAuthResponse({ ...response?.data, user: setDefaultOrganizationRoleIfMissed(user) });
    }

    setLoginForm({
      identifier: '',
      password: '',
    });
    navigate('/index/survey');
  };

  const handleInputChange = (formControlName, originalEvent) => {
    const formControlUpdatedValue =
      originalEvent instanceof Object ? unwrapValueFromInputChangeEvent(originalEvent) : originalEvent;

    const formUpdate = {
      ...loginFormStore,
      [formControlName]: formControlUpdatedValue || undefined,
    };
    setLoginForm(formUpdate);
  };

  return (
    <>
      <h1 className="text-main-black text-xxl mb-[16px]">Login</h1>
      <CulturlabsInput
        label="email"
        className="mb-[16px]"
        required
        handleChange={changeEvent => handleInputChange('identifier', changeEvent)}
      />
      <CulturlabsInput
        label="password"
        inputType="password"
        required
        handleChange={changeEvent => handleInputChange('password', changeEvent)}
      />
      <CulturlabsLink
        className="block font-semibold mt-[8px] mb-[16px] text-s"
        label="Forgot Password?"
        href="/forgot-password"
      />
      <CulturlabsButton disabled={!formValid} type="submit" className="mt-[24px] w-full" onClick={onSubmit}>
        Login
      </CulturlabsButton>
    </>
  );
};

export default LoginPage;
