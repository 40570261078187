import { Outlet } from 'react-router-dom';

import NavigationSidebar from '../NavigationSidebar/NavigationSidebar';

const MainAppContainer = () => {
  return (
    <div className="flex flex-row bg-lightgray overflow-hidden max-w-[100vw] max-h-[100vh]">
      <NavigationSidebar />
      <main className="relative  h-[98%] grow flex items-center justify-center">
        {' '}
        <Outlet />
      </main>
    </div>
  );
};

export default MainAppContainer;
