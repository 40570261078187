import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsChip from '../atoms/CulturlabsChip/CulturlabsChip';

const InterventionCard = ({
  intervention: {
    id,
    title,
    functionValue,
    headline,
    factors: { data },
  },
  close = emptyArrowFunc,
  click = emptyArrowFunc,
}) => {
  return (
    <>
      <div className="basis-[283px] max-w-[300px] grow rounded-[24px] bg-white overflow-hidden">
        <div className="p-[16px] h-[80px] bg-main-black w-full flex flex-row items-center">
          <h1 className="text-white font-lato text-ml text-center grow text-start max-w-[220px]" onClick={close()}>
            {title}
          </h1>
          <div className="border border-white border rounded-full h-[32px] w-[32px] text-white flex justify-center items-center">
            {id}
          </div>
        </div>

        <div className="p-[16px]">
          <div className="flex flex-row flex-wrap h-[70px]">
            {data?.map(({ attributes: { label } }) => (
              <CulturlabsChip className="mb-[16px]" label={label} disabled />
            ))}{' '}
          </div>
          <p className="mb-[8px] text-ml font-[700]">
            Function:<span className="font-[500]"> {functionValue}</span>
          </p>
          <p className="mb-[8px] text-ml font-[700]">Headline:</p>
          <p className="mb-[8px] text-ml h-[96px] overflow-hidden">{headline}</p>
          <CulturlabsButton className="w-full mt-[16px]" onClick={() => click()}>
            View
          </CulturlabsButton>
        </div>
      </div>
    </>
  );
};

export default InterventionCard;
