import axios from 'axios';

const APIService = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return {
    call: async ({ url, method, data, headers, ...rest }) => {
      const callConfig = {
        method,
        url,
        ...rest,
      };
      if (data) {
        callConfig.data = data;
      }
      if (headers) {
        callConfig.headers = { ...axios.defaults.headers, ...headers };
      }

      return await axios(callConfig);
    },
    urls: {
      auth: `${baseUrl}/auth/local`,
      register: `${baseUrl}/auth/local/register`,
      me: `${baseUrl}/users/me`,
      organizations: `${baseUrl}/organizations`,
      employees: `${baseUrl}/user/getEmployees`,
      departments: `${baseUrl}/departments`,
      teams: `${baseUrl}/teams`,
      levels: `${baseUrl}/levels`,
      regions: `${baseUrl}/regions`,
      users: `${baseUrl}/users`,
      assignedSurvey: `${baseUrl}/assigned-surveys`,
      assignedSurveyImport: `${baseUrl}/assigned-survey/import`,
      surveys: `${baseUrl}/surveys`,
      exportPassedSurveys: `${baseUrl}/passed-survey/export`,
      questionAnswer: `${baseUrl}/question-answers`,
      questionsFactors: `${baseUrl}/factors`,
      questionsSubFactors: `${baseUrl}/subfactors`,
      questionsCategory: `${baseUrl}/questions-categories`,
      invitedUsers: `${baseUrl}/invited-users`,
      feedback: `${baseUrl}/feedbacks`,
      forgotPassword: `${baseUrl}/auth/forgot-password`,
      resetPassword: `${baseUrl}/auth/reset-password`,
      interventions: `${baseUrl}/interventions?populate=*`,
      rateSurveys: `${baseUrl}/rate-surveys`,
      uploadCsv: `${baseUrl}/invited-users/upload-csv`,
    }, //TODO sanitize users teams etc
  };
};

export default APIService();
