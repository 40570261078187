import { ReactComponent as MinusSVG } from '../../assets/temperature/minus50.svg';
import { ReactComponent as PlusSVG } from '../../assets/temperature/plus50.svg';
import { ReactComponent as TriangleSVG } from '../../assets/triangle.svg';
import FactorCircleBar from '../FactorCircleBar/FactorCircleBar';

const TO_FIXED_SUBFACTOR_TEMPERATURE_VALUE = 0;

const SubFactorCard = ({ options, label }) => {
  const { value, description } = options;

  const normalizedValue = value - 50;
  const beautifiedValue =
    normalizedValue > 0
      ? `+${normalizedValue.toFixed(TO_FIXED_SUBFACTOR_TEMPERATURE_VALUE)}`
      : normalizedValue.toFixed(TO_FIXED_SUBFACTOR_TEMPERATURE_VALUE);

  return (
    <>
      <div className={getClasses()}>
        <h1 className="p-[24px] h-[88px] bg-main-black w-full text-white font-lato text-sub-factor text-center">
          {label}
        </h1>
        <p className="grow m-[24px] mb-0 text-ml">{description} </p>
        <div className="relative flex justify-center items-center -mt-[40px] h-[350px]">
          <TriangleSVG className="absolute top-[35%]" />
          <FactorCircleBar className="relative" options={{ label, value }} />
          <div className="absolute w-[240px] bottom-[60px] flex flex-row">
            <MinusSVG /> <PlusSVG className="ml-auto" />
          </div>
          <h2 className="absolute bottom-[30%] text-main-black text-xxxl font-bold w-[200px] text-center justify-between">
            {beautifiedValue}
          </h2>
        </div>
      </div>{' '}
    </>
  );
};

export default SubFactorCard;

const getClasses = () =>
  `relative overflow-hidden h-[513px] w-[385px] shadow-temperature-card bg-white flex flex-col justify-center items-center rounded-[24px]`;
