import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as LeftArrowSVG } from '../../assets/left-arrow.svg';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import factorsMapper from '../../helpers/factorsMapper.helper';
import getFilterOfOrganization from '../../helpers/getFilterOfOrganization.helper';
import proceedAnswerValue from '../../helpers/proceedAnswerValue.helper';
import APIService from '../../services/API.service';
import SubFactorCard from '../SubFactorCard/SubFactorCard';

const SubFactorsPage = () => {
  const factor = useParams().factor;

  const [searchParams] = useSearchParams();

  const [factors, setFactors] = useState([]);
  const [subFactors, setSubFactors] = useState([]);

  const [answers, setAnswers] = useState([]);
  const [sortedStatistic, setSortedStatistic] = useState({});
  const [config, setConfig] = useState([]);

  const init = async () => {
    const bearer = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${bearer}` };

    await APIService.call({
      method: 'get',
      url: APIService.urls.questionsFactors,
      headers,
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => {
        const flat = data.data.map(({ id, attributes }) => ({
          id,
          ...attributes,
        }));

        setFactors(flat);
      });

    await APIService.call({
      method: 'get',
      url: APIService.urls.questionsSubFactors,
      headers,
      params: {
        populate: '*',
      },
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => {
        const flat = data.data.map(({ id, attributes }) => ({
          id,
          ...attributes,
          factor: { id: attributes.factor.data.id, ...attributes.factor.data.attributes },
        }));

        setSubFactors(flat);
      });

    await APIService.call({
      method: 'get',
      url: APIService.urls.questionAnswer,
      headers,
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => {
        setAnswers(data.data);
      });
  };

  const descriptorMapper = subfactor => subFactors.find(({ value }) => value == subfactor)?.descriptor;

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const team = searchParams.get('team');
    const department = searchParams.get('dept');
    const selectionFiltersStore = {
      team: (team !== 'null' && team !== 'undefined' && team) || '-',
      department: (department !== 'null' && department !== 'undefined' && department) || '-',
    };
    const isFiltersEnabled = Object.values(selectionFiltersStore).filter(value => value != '-')?.length > 0;

    const filterOfCurrentSelection = getFilterOfOrganization(selectionFiltersStore, isFiltersEnabled);

    const sortedByFactor = answers
      ?.filter(answer => !!answer && filterOfCurrentSelection(answer))
      .reduce((reducer, answer) => {
        const { value: factor } = answer.factor;
        const { value: subfactor } = answer.subfactor;

        const answerValue = proceedAnswerValue(answer.answer_value);

        if (!reducer[factor]?.length) reducer[factor] = [];

        reducer[factor].push({
          answerValue,
          factor,
          subfactor,
        });

        return reducer;
      }, {});

    setSortedStatistic(sortedByFactor);
  }, [answers]);

  useEffect(() => {
    const sortedAnswers = sortedStatistic[mapParamFactorToFactorValue(factor)];
    const bySubfactors = sortedAnswers?.reduce((sortedBySubfactor, answer) => {
      const { subfactor } = answer;
      if (!sortedBySubfactor[subfactor]) sortedBySubfactor[subfactor] = [];
      sortedBySubfactor[subfactor].push(answer);

      return sortedBySubfactor;
    }, {});

    if (!bySubfactors) {
      const config = subFactors.reduce((acc, subfactor) => {
        if (subfactor.factor.value !== mapParamFactorToFactorValue(factor)) return acc;

        return [
          ...acc,
          {
            title: subfactor.value,
            description: descriptorMapper(subfactor.value),
            label: factorsMapper(factors, subfactor.value),
            value: 50,
          },
        ];
      }, []);

      setConfig(config);
      return;
    }

    const config = Object.keys(bySubfactors).map(subfactor => {
      const answers = bySubfactors[subfactor];
      const sum = answers.reduce((sum, { answerValue }) => {
        return sum + answerValue;
      }, 0);

      return {
        title: subfactor,
        description: descriptorMapper(subfactor),
        label: factorsMapper(factors, subfactor),
        value: (bySubfactors[subfactor] = sum / answers.length),
      };
    });

    setConfig(config);
  }, [sortedStatistic]);

  return (
    <>
      <section className="w-full bg-lightgray">
        <div className="flex flex-col w-full overflow-y-auto h-[calc(100vh-40px)]">
          <a href="/index/temperature/overview">
            <h1 className="flex flex-row items-center text-main-black text-xxl font-lato font-bold mb-[32px] capitalize [&>*]:fill-main-black">
              <LeftArrowSVG />
              {factor}
            </h1>
          </a>
          <div className="flex flex-row flex-wrap gap-[24px] justify-items-start">
            {config?.map(option => (
              <SubFactorCard label={factorsMapper(subFactors, option.label)} options={option} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default SubFactorsPage;

const mapParamFactorToFactorValue = param => {
  switch (param) {
    case 'leadership':
      return 'Leadership';
    case 'policy':
      return 'Policy';
    case 'knowledge':
      return 'Knowledge';
    case 'social-environment':
      return 'SocialEnvironment';
    default:
      return param;
  }
};
