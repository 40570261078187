import { useEffect, useState } from 'react';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

import DEFAULT_CHIPS_VALUES from '../../constants/DEFAULT_CHIPS_VALUES';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import getFilterOfOrganization from '../../helpers/getFilterOfOrganization.helper';
import APIService from '../../services/API.service';
import useAppStore from '../../store';
import Loading from '../Loading/Loading';
import NavTabsTemperature from '../NavTabsTemperature/NavTabsTemperature';
import StatisticsSelectionControls from '../StatisticsSelectionControls/StatisticsSelectionControls';
import CulturlabsChip from '../atoms/CulturlabsChip/CulturlabsChip';

const TemperatureStatisticsPage = () => {
  const selectionFiltersStore = useAppStore(state => state.selectionFilters);
  const isFiltersEnabled = Object.values(selectionFiltersStore).filter(value => value != '-')?.length > 0;
  const setSelectionFilters = useAppStore(state => state.setSelectionFilters);
  const [chipsState, setChipsState] = useState(DEFAULT_CHIPS_VALUES);
  const [statistics, setStatistics] = useState([]);
  const [questionsFactors, setQuestionsFactors] = useState([]);
  const [averageResults, setAverageResults] = useState({});
  const [loading, setLoading] = useState(false);

  const init = async () => {
    try {
      setLoading(true);

      const bearer = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${bearer}` };

      await APIService.call({
        method: 'get',
        url: APIService.urls.questionAnswer,
        headers,
      })
        .catch(emptyArrowFunc)
        .then(({ data }) => setStatistics(data.data));

      const factorsResponse = await APIService.call({
        method: 'get',
        url: APIService.urls.questionsFactors,
        headers,
      })
        .catch(emptyArrowFunc)
        .then(({ data }) => data.data);

      const factors = factorsResponse.map(({ id, attributes }) => ({
        id,
        ...attributes,
      }));
      setQuestionsFactors(factors);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setSelectionFilters({ department: '-', team: '-' });
  }, []);

  useEffect(() => {
    const sortedStatistics = {};
    statistics
      .filter(answer => !!answer)
      .forEach(answer => {
        const factor = answer.factor.value;
        if (!sortedStatistics[factor]) sortedStatistics[factor] = [];
        if (answer) sortedStatistics[factor].push(answer);
      });

    buildChartData(sortedStatistics, setAverageResults, selectionFiltersStore, isFiltersEnabled);
  }, [statistics, selectionFiltersStore]);

  const proceedChipSelection = chipSelection => {
    const { checked } = chipSelection;
    const newState = {
      ...chipsState,
      [chipSelection['label']]: checked,
    };
    setChipsState(newState);
  };

  const seriesWithCurrentFilters = getActiveSeries(chipsState, averageResults, questionsFactors);

  const state = {
    series: seriesWithCurrentFilters,
    options: {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      yaxis: {
        show: false,
      },
      xaxis: {
        type: 'datetime',
        categories: statistics?.filter(data => !!data).map(({ createdAt }) => createdAt),
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        y: {
          formatter: value => (Number.parseInt(value) - 50)?.toFixed(0),
        },
      },
      legend: {
        show: true,
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      colors: ['#8AB1F9', '#ad7afa', '#144DEB', '#491EBD'],
    },
  };

  return (
    <section className="w-full h-[calc(100vh-90px)] p-[48px] overflow-y-auto">
      <Loading loading={loading} loadingMessage="Generating Results">
        <div className="flex flex-col w-full">
          <h1 className="text-main-black text-xxl font-lato font-bold">Temperature</h1>
          <NavTabsTemperature className="my-[32px]" />
          <StatisticsSelectionControls />
          <div className="flex flex-col">
            <main className="bg-white shadow-general-shadow-01 rounded-[20px]">
              <div className="px-[70px] pb-[50px] pt-[40px] flex flex-row">
                <h2 className="text-lato color-main-black ml-[20px] text-sub-factor font-semibold">Change Over time</h2>
                <CulturlabsChip
                  selected={true}
                  label="Leadership"
                  className="ml-auto h-[32px] px-[16px] [&>*]:text-m"
                  showCross={false}
                  onClickCallback={selection => proceedChipSelection(selection)}
                />
                <CulturlabsChip
                  selected={true}
                  label="Policy"
                  className="ml-[16px] h-[32px] px-[16px] [&>*]:text-m"
                  showCross={false}
                  onClickCallback={selection => proceedChipSelection(selection)}
                />
                <CulturlabsChip
                  selected={true}
                  label="Knowledge"
                  className="ml-[16px] h-[32px] px-[16px] [&>*]:text-m"
                  showCross={false}
                  onClickCallback={selection => proceedChipSelection(selection)}
                />
                <CulturlabsChip
                  selected={true}
                  label="Social Environment"
                  className="ml-[16px] h-[32px] px-[16px] [&>*]:text-m"
                  showCross={false}
                  onClickCallback={selection => proceedChipSelection(selection)}
                />
              </div>
              <ReactApexChart options={state.options} series={state.series} type="area" height={350} />
            </main>
          </div>
        </div>
      </Loading>
    </section>
  );
};

export default TemperatureStatisticsPage;

const buildChartData = (sortedStatisticsByCategory, setAverageResults, selectionFiltersStore, isFiltersEnabled) => {
  const factors = Object.keys(sortedStatisticsByCategory);
  const averageSeriesByCategory = {};

  const isAnswerAccordingCurrentFilters = getFilterOfOrganization(selectionFiltersStore, isFiltersEnabled);

  factors.forEach(factor => {
    const answersRelatedToFactor = sortedStatisticsByCategory[factor];
    const groupedAnswersBySurvey = {};

    answersRelatedToFactor.forEach(answer => {
      if (!groupedAnswersBySurvey[answer.passed_survey_id]) groupedAnswersBySurvey[answer.passed_survey_id] = [];
      // TODO: if passed_survey_id == undefined
      if (isAnswerAccordingCurrentFilters(answer)) groupedAnswersBySurvey[answer.passed_survey_id].push(answer);
    });

    cleanupGroupedAnswers(groupedAnswersBySurvey);

    const passedSurveysIds = Object.keys(groupedAnswersBySurvey);
    const seriesOfCategory = buildAverageSeriesBySurveys(passedSurveysIds, groupedAnswersBySurvey);

    averageSeriesByCategory[factor] = {
      factor,
      series: seriesOfCategory,
    };
  });

  setAverageResults(averageSeriesByCategory);
};

const buildAverageSeriesBySurveys = (passedSurveysIds, groupedAnswersBySurvey) =>
  passedSurveysIds.map(passedSurveyId => {
    const averageValueBySurvey =
      groupedAnswersBySurvey[passedSurveyId].reduce((sum, answer) => {
        const updatedSum = sum + Number.parseInt(answer.answer_value);
        return updatedSum;
      }, 0) / groupedAnswersBySurvey[passedSurveyId].length;

    const averageResult = [groupedAnswersBySurvey[passedSurveyId][0]?.createdAt, averageValueBySurvey];

    return averageResult;
  });

const getActiveSeries = (chipsState, averageResults, questionsFactors) => {
  try {
    const enabledChips = Object.entries(chipsState).reduce(
      (reducer, [chipLabel, isEnabled]) => (isEnabled ? [...reducer, chipLabel] : [...reducer]),
      [],
    );

    const enabledSeries = enabledChips.reduce((reducer, chipLabel) => {
      const respectiveFactor = questionsFactors.find(factor => factor.value === chipLabel);

      const seriesData = averageResults[respectiveFactor.value]?.series;

      if (seriesData)
        reducer.push({
          name: chipLabel,
          data: seriesData,
        });

      return reducer;
    }, []);

    return enabledSeries;
  } catch {
    return [];
  }
};

const cleanupGroupedAnswers = grouped => {
  const keys = Object.keys(grouped);
  keys.forEach(key => {
    if (!grouped[key].length) delete grouped[key];
  });
};
