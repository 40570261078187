import useCurrentUser from '../hooks/useCurrentUser';
import useAppStore from '../store';
import checkAdminsRequirements from './helpers/checkAdminsRequirements.helper';
import checkAuthorizedRequirements from './helpers/checkAuthorizedRequirements.helper';
import checkNonAuthorizedRequirements from './helpers/checkNonAuthorizedRequirements.helper';

export const DisplayIfAllowed = ({
  rule,
  children,
  organizationAdminOnly,
  hideForCorporativeAdmin,
  hideForRegularCorporativeUser,
}) => {
  const { jwt } = useAppStore(state => state.auth) || localStorage.getItem('token');
  const { currentUser, isCorporative, isCorporativeAdmin } = useCurrentUser();

  if (
    checkAuthorizedRequirements(rule, jwt) ||
    checkNonAuthorizedRequirements(rule, jwt) ||
    checkAdminsRequirements(rule, jwt, currentUser) ||
    (organizationAdminOnly && (!isCorporative || !isCorporativeAdmin)) ||
    (hideForCorporativeAdmin && isCorporative && isCorporativeAdmin) ||
    (hideForRegularCorporativeUser && isCorporative && !isCorporativeAdmin)
  ) {
    return null;
  }

  return children;
};

export default DisplayIfAllowed;
