import classNames from 'classnames';

const CulturlabsButton = ({ children, className, style, onClick, secondary, primary, disabled }) => {
  primary = primary || !secondary;

  return (
    <button
      disabled={disabled}
      className={classNames(getClassNames(primary, disabled), className)}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default CulturlabsButton;

const getClassNames = (primary, disabled) =>
  `${commonClasses()} ${primary ? getPrimaryClasses() : getSecondaryClasses()} ${disabled && 'cursor-not-allowed'}`;

const commonClasses = () =>
  'box-border cursor-pointer flex justify-center items-center h-[48px] px-[16px]  text-l radius-[24px] rounded-[24px]';

const getPrimaryClasses = () =>
  'text-white [&>*]:text-white bg-main-blue hover:bg-blue-01 hover:shadow-button-primary active:bg-blue-02 disabled:bg-blue-03 disabled:text-gray-disabled disabled:cursor-not-allowed ';

const getSecondaryClasses = () =>
  'text-blue-01 [&>*]:text-blue-01 bg-white border-solid border border-blue-01 active:border-blue-02 hover:shadow-button-primary active:[&>*]:text-blue-02 active:text-blue-02 disabled:cursor-not-allowed disabled:border-blue-04 disabled:[&>*]:text-blue-04 disabled:text-blue-04';
