import React from 'react';
import Markdown from 'react-markdown';

import { ReactComponent as LeftArrowSVG } from '../../assets/left-arrow.svg';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import CulturlabsChip from '../atoms/CulturlabsChip/CulturlabsChip';
import './InterventionCardFull.css';

// Custom link renderer
const LinkRenderer = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const InterventionCardFull = ({
  intervention: {
    id,
    title,
    functionValue,
    content,
    factors: { data },
  },
  close = emptyArrowFunc,
}) => {
  return (
    <>
      <div className="w-full rounded-[24px] bg-white overflow-hidden">
        <div className="p-[16px] h-[80px] bg-main-black w-full flex flex-row items-center" onClick={() => close()}>
          <LeftArrowSVG className="fill-white cursor-pointer mr-[24px]" />
          <div className="flex flex-col">
            <h1 className="text-white font-lato sub-factor font-bold text-ml text-center grow text-start">{title}</h1>
            <div className="flex flex-row">
              <div className="flex flex-row flex-wrap">
                {data?.map(({ attributes: { label } }) => (
                  <CulturlabsChip
                    key={label} // Assuming label is unique, add a key here
                    className="mb-[16px]"
                    label={label}
                    disabled
                  />
                ))}{' '}
              </div>
              <p className="text-lightgray">
                <span className="font-bold">Function:</span> {functionValue}
              </p>
            </div>
          </div>
          <div className="ml-auto border border-white border rounded-full h-[32px] w-[32px] text-white flex justify-center items-center">
            {id}
          </div>
        </div>
        <div className="p-[16px] py-[32px] markdown">
          <Markdown components={{ a: LinkRenderer }}>{content}</Markdown>
        </div>
      </div>
    </>
  );
};

export default InterventionCardFull;
