import { useEffect } from 'react';
import { useState } from 'react';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import APIService from '../../services/API.service';
import EmptyStatePage from '../EmptyStatePage/EmptyStatePage';
import QuestionPage from '../QuestionPage/QuestionPage';

const SurveyComponent = ({ employeeInfo, onSend = emptyArrowFunc }) => {
  const [survey, setSurvey] = useState(null);
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentIndexQuestion] = useState(0);
  const questions = survey?.questions || [];
  const token = localStorage.getItem('token');

  useEffect(() => {
    init();
  }, [survey]);

  const getCurrentSurvey = async () => {
    const callConfig = {
      url: APIService.urls.assignedSurvey,
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await APIService.call(callConfig).catch(emptyArrowFunc);
    if (response?.status !== 200) {
      return;
    }

    return response;
  };
  const isFirstQuestion = currentQuestionIndex => currentQuestionIndex === 0;

  const isLastQuestion = (questions, currentQuestionIndex) => questions.length - 1 === currentQuestionIndex;

  const isEveryAnswerAccumulated = (questions, answers) => questions.length === Object.keys(answers || {}).length;

  const upsertNewAnswer = (answer, answers, currentQuestionIndex) => ({
    ...answers,
    [currentQuestionIndex]: answer,
  });

  const sendAnswer = async answer => {
    const callConfig = {
      url: APIService.urls.questionAnswer,
      method: 'post',
      headers: { Authorization: `Bearer ${token}` },
      data: answer,
    };
    const response = await APIService.call(callConfig).catch(emptyArrowFunc);
    if (response?.status !== 200) {
      return;
    }

    return response;
  };

  const init = async () => {
    if (!survey && token?.length > 1) getCurrentSurvey()?.then(({ data: { data } }) => setSurvey(data)); //todo null on back
  };

  const onPrevious = answer => {
    setAnswers(upsertNewAnswer(answer, answers, currentQuestionIndex));
    setCurrentIndexQuestion(currentQuestionIndex - 1);
  };

  const onNext = async answer => {
    const upserted = upsertNewAnswer(answer, answers, currentQuestionIndex);
    setAnswers(upserted);
    setCurrentIndexQuestion(currentQuestionIndex + 1);
    if (currentQuestionIndex + 1 === questions?.length) {
      // TODO fix

      const requestData = {
        survey: survey.id,
        answers: upserted,
        type: survey.type,
      };
      if (employeeInfo) {
        requestData.employeeInfo = employeeInfo;
      }
      const answerResponse = (await sendAnswer(requestData))?.data;

      setSurvey(null);
      setCurrentIndexQuestion(0);
      onSend({ answerResponse });
    }
  };
  localStorage.setItem('haveSurvey', survey ? true : false);

  if (!survey) {
    return <EmptyStatePage type="noSurveys" hideLogo />;
  }

  return (
    <QuestionPage
      questions={questions}
      currentQuestion={questions?.[currentQuestionIndex]}
      currentAnswer={answers[currentQuestionIndex]}
      onNext={onNext}
      onPrevious={onPrevious}
      isFirstQuestion={isFirstQuestion(currentQuestionIndex)}
      isLastQuestion={isLastQuestion(questions, currentQuestionIndex)}
      isEveryAnswerAccumulated={isEveryAnswerAccumulated(questions, answers)}
    />
  );
};

export default SurveyComponent;
