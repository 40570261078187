import { useState } from 'react';

import { ReactComponent as CrossSVG } from '../../../assets/chip/cross.svg';
import emptyArrowFunc from '../../../helpers/emptyArrowFunc.helper';

const CulturlabsChip = ({
  className,
  label,
  onClickCallback = emptyArrowFunc,
  disabled,
  showCross = true,
  selected,
  mapper = () => label,
}) => {
  const [checked, setChecked] = useState(selected);

  const onClick = () => {
    if (disabled) return;
    onClickCallback({ label, checked: !checked });

    setChecked(!checked);
  };

  return (
    <button
      className={`${getClassName(
        checked,
      )} rounded-[16px] h-[24px] flex flex-row items-center px-[8px] mr-[8px] ${className}`}
      onClick={() => onClick()}
    >
      <span className="font-inter text-s "> {mapper(label)}</span>

      {checked && showCross ? <CrossSVG /> : <></>}
    </button>
  );
};

export default CulturlabsChip;

const getClassName = checked =>
  `${
    checked ? 'text-white bg-chip-blue [&>*]:fill-white' : 'text-main-black bg-lightest-blue [&>*]:fill-main-black'
  }  `;
