import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useAppStore = create(
  devtools(set => ({
    me: {},
    setMe: updatedMe =>
      set(state => ({
        ...state,
        me: updatedMe,
      })),
    auth: {},
    setAuth: updatedAuth =>
      set(state => ({
        ...state,
        auth: updatedAuth,
      })),
    loginForm: {},
    setLoginForm: updateForLoginForm =>
      set(state => ({
        ...state,
        loginForm: updateForLoginForm,
      })),
    registerForm: {},
    setRegisterForm: updateForRegisterForm =>
      set(state => ({
        ...state,
        registerForm: updateForRegisterForm,
      })),
    selectionFilters: {},
    setSelectionFilters: updateForSelectionFilters =>
      set(state => ({
        ...state,
        selectionFilters: updateForSelectionFilters,
      })),
    sortedStatistic: [],
    setSortedStatistic: updateForCalculatedStatByCurrentFilters =>
      set(state => ({
        ...state,
        calculatedStatByCurrentFilters: updateForCalculatedStatByCurrentFilters,
      })),
  })),
);

export default useAppStore;
