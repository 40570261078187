import { useEffect, useState } from 'react';

import DEFAULT_CHIPS_VALUES from '../../constants/DEFAULT_CHIPS_VALUES';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import factorsMapper from '../../helpers/factorsMapper.helper';
import APIService from '../../services/API.service';
import useAppStore from '../../store';
import InterventionCard from '../InterventionCard/InterventionCard';
import InterventionCardFull from '../InterventionCardFull/InterventionCardFull';
import CulturlabsChip from '../atoms/CulturlabsChip/CulturlabsChip';

const InterventionCataloguePage = () => {
  const [chipsState, setChipsState] = useState(DEFAULT_CHIPS_VALUES);
  const [selectedIntervention, setSelectedIntervention] = useState(null);
  const [interventions, setInterventions] = useState([]);
  const [filteredInterventions, setFilteredInterventions] = useState([]);
  const [factors, setFactors] = useState([]);

  const init = async () => {
    const bearer = localStorage.getItem('token');

    const headers = { Authorization: `Bearer ${bearer}` };

    await APIService.call({
      method: 'get',
      url: APIService.urls.questionsFactors,
      headers,
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => {
        const flat = data.data.map(({ id, attributes }) => ({
          id,
          ...attributes,
        }));

        setFactors(flat);
      });

    await APIService.call({
      method: 'get',
      url: APIService.urls.interventions,
      headers,
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => {
        const flat = data.data.map(({ id, attributes }) => ({
          id,
          ...attributes,
        }));

        setInterventions(flat);
      });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    filterInterventions();
  }, [chipsState, interventions]);

  const filterInterventions = () => {
    const filtered = interventions.filter(int => filterByCurrentSelection(int));
    setFilteredInterventions(filtered);
  };

  const proceedChipSelection = chipSelection => {
    const { checked } = chipSelection;
    const newState = {
      ...chipsState,
      [chipSelection['label']]: checked,
    };
    setChipsState(newState);
  };

  const filterByCurrentSelection = ({ factors: { data } }) =>
    Object.entries(chipsState).some(([factorValue, isEnabled]) =>
      data.some(({ attributes: { value } }) => isEnabled && factorValue === value),
    );

  return (
    <>
      <section className="w-full bg-lightgray ">
        <div className="flex flex-col w-full  h-[calc(100vh-40px)] p-[48px] overflow-y-auto">
          <h1 className="flex flex-row items-center text-main-black text-xxl font-lato font-bold mb-[32px] capitalize">
            Intervention Catalogue
          </h1>
          <div className="flex flex-row mb-[28px] flex items-center">
            <span className="font-lato text-semi-xl text-main-black font-[700] mr-[16px]">Tags</span>
            <CulturlabsChip
              selected={true}
              label="Leadership"
              onClickCallback={proceedChipSelection}
              mapper={() => factorsMapper(factors, 'Leadership')}
            />
            <CulturlabsChip
              selected={true}
              label="Policy"
              onClickCallback={proceedChipSelection}
              mapper={() => factorsMapper(factors, 'Policy')}
            />
            <CulturlabsChip
              selected={true}
              label="Knowledge"
              onClickCallback={proceedChipSelection}
              mapper={() => factorsMapper(factors, 'Knowledge')}
            />
            <CulturlabsChip
              selected={true}
              label="SocialEnvironment"
              onClickCallback={proceedChipSelection}
              mapper={() => factorsMapper(factors, 'SocialEnvironment')}
            />
          </div>
          <div className="flex flex-row flex-wrap justify-between gap-[24px] justify-items-start">
            {selectedIntervention ? (
              <InterventionCardFull intervention={selectedIntervention} close={() => setSelectedIntervention(null)} />
            ) : (
              filteredInterventions.map(intervention => (
                <InterventionCard
                  key={intervention.id}
                  intervention={intervention}
                  click={() => setSelectedIntervention(intervention)}
                ></InterventionCard>
              ))
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default InterventionCataloguePage;
